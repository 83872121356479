import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

import AppHeader from 'Components/AppHeader'
import GS from 'Theme/globalStyles'
import styled from 'styled-components'

import AppLoadingSpinner from 'Components/AppSpinner'
import 'aos/dist/aos.css'

const HomePage = React.lazy(() => import('Pages/Home/Home'))

const HeardNotification = styled.div`
  height: 40px;
  background-color: #454951;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e45244;
  font-size: 16px;
`

const ReportProblemIcon = styled.svg`
  fill: #e45244;
  width: 20px;
  margin-right: 10px;
`

function App() {
  useEffect(() => {
  }, [])

  return (
    <React.Fragment>
      <GS.GlobalStyle />
      <HeardNotification>
        <ReportProblemIcon focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path></ReportProblemIcon>
        Experimental, unaudited BETA system. Use at your own risk
      </HeardNotification>
      <AppHeader />
      <section>
        <GS.MainBox>
          <Routes>
            <Route
              path='/'
              element={
                <React.Suspense fallback={<AppLoadingSpinner />}>
                  <HomePage />
                </React.Suspense>
              }
            />
          </Routes>
        </GS.MainBox>
      </section>
    </React.Fragment>
  )
}

export default App
