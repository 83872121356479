import styled, { createGlobalStyle } from 'styled-components'
var Gs = {}

Gs.GlobalStyle = createGlobalStyle`
  :root{
    --app-ff-primary: 'Montserrat', sans-serif;
    --app-ff-secondary: 'Lato', sans-serif;
  }
  body {
    margin: 0;
    padding: 0; 
    background-color: ${({ theme }) => theme.bodybg015}; 
    color: ${(props) => props.theme.colorWhite}; 
  }
  span, div, text-area, button, p, text-content {
    font-family: var(--app-ff-primary) !important;
  }
  h1,h2,h3,h4,h5,h6,.text-title,.h1,.h2,.h3,.h4,.h5,.h6 {
    font-family: var(--app-ff-secondary) !important;
  }

  .app-ff-primary {
    font-family: var(--app-ff-primary);
  }
  .app-ff-secondary {
    font-family: var(--app-ff-secondary);
  }

  .orangeColor{color:${(props) => props.theme.colorGreen} !important; }
  .text-primary{color:${(props) => props.theme.colorGreen} !important; }
  .text-danger{color:${(props) => props.theme.colorRed} !important; }
  .text-muted{
    color: ${(props) => props.theme.colorLightGrey} !important;
  }
  .text-primary-2{color:${(props) => props.theme.colorGreen2} !important; }
  .text-gray-3{color:${(props) => props.theme.colorGrey3} !important; }
  .text-gray-4{color:${(props) => props.theme.colorGrey4} !important; }
  .text-dark-1{color:${(props) => props.theme.colorDark01} !important; }
  .text-secondary{color:${(props) => props.theme.colorBlue01} !important; }
  .data-tooltip {
    max-width: 500px;
    color: #000000;
  }
  .helpIco{ position:relative; right:-5px; top:-5px; }

  img{ max-width:100%; height:auto;}

    @media (max-width:767px){
      .MainBox{  background-image:none; }
      
     }

.hover\\:border-highlight-shadow-primary:hover {
  border-color: ${(props) => props.theme.colorGreen};
  box-shadow: ${(props) => props.theme.colorGreen} 0px 0px 20px 1px;
}

.border-highlight-shadow{
  &-primary {
    border-color: ${(props) => props.theme.colorGreen};
    box-shadow: ${(props) => props.theme.colorGreen} 0px 0px 10px 1px;
  }
  &-gray {
    border-color: ${(props) => props.theme.colorGrey};
    box-shadow: ${(props) => props.theme.colorGrey} 0px 0px 10px 1px;
  }
}
  .fs-{
    ${(props) =>
      Object.entries(props.theme.fontSize).map(
        ([key, value]) => `
      &${key} {
        font-size: ${value} !important;
      }
    `
      )}
  }
  ${(props) =>
    Object.entries(props.theme)
      .filter(([key, value]) => typeof value === 'string')
      .map(
        ([key, value]) =>
          `.bg-${key} {
            background-color: ${value} !important;
          }
          .text-${key} {
            color: ${value} !important;
          }
          .hover\\:text-${key} {
            &:hover{
              color: ${value} !important;
            }
          }
          .hover\\:border-highlight-${key}:hover{
            box-shadow: ${value} 0px 0px 22px 0px;
          }
          .border-color-${key} {
            border-color: ${value} !important;
          }
          `
      )}
  .highlighted-border-bottom{
    position: relative;
    &:after {
      content: '';
      width: inherit;
      height: 2px;
      position: absolute;
      background: linear-gradient(#000000 50%,#292c32 50%);
    }
  }
  .highlighted-border-right{
    position: relative;
    &:after {
      content: '';
      width: 2px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background: linear-gradient(90deg, #000000 50%,#292c32 50%);
    }
  }

  .app-shadow{
    &-1{
      box-shadow: 0px 0px 10px -5px;
    }
  }
  .bg-img-primary{
    background: url('/images/shield-bg.png') no-repeat center 20px;
  }
  .text-shadow{
    &-primary{
      text-shadow: 0 0 9px ${(props) => props.theme.colorGreen02};
    }
  }
  .hover\\:{
    &-text-none {
      color: none !important;
    }
  }
  .hero-bg {
    background-repeat: no-repeat;
    background-image: url(/images/hero-background-left.jpg);
    background-position-x: left;
    background-position-y: bottom;
  }
  
  .w-fit-content {
    width: fit-content !important;
  }

`

Gs.MainBox = styled.div`
  margin: 110px 0 0 0;
  background: url('/images/shield-bg.png') no-repeat center center;
`

Gs.Container = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 10px;
  max-width: 1420px;
`

export default Gs
