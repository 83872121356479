import React from 'react'
import styled from 'styled-components'
import { AppButton } from './core/Buttons'

const SLogo = styled.img`
  width: 260px;
  min-width: 260px;
  height: 70px;
  min-height: 70px;
`
const SHeader = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
`

function AppHeader() {
  return (
    <SHeader>
      <div className='overflow-hidden'>
        <SLogo src='/images/DEREX-logo.png' alt='' />
      </div>
      <div className='d-flex align-items-center'>
        <AppButton as='a' href='https://reward.quantum.foundation' target='_blank' className='outline-none text-nowrap text-uppercase fs-sm'>Reward Program</AppButton>
        <AppButton as='a' href='https://app.derex.exchange' target='_blank' className='outline-primary text-uppercase text-nowrap fs-sm px-25 py-10 ml-20'>
          ENTER app
        </AppButton>
      </div>
    </SHeader>
  )
}

export default AppHeader
