import styled from 'styled-components'

export const AppButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colorWhite};
  border: none;
  background-color: ${(props) => props.theme.colorGrey};
  font-weight: 700;
  font-size: 21px;
  width: 100%;
  padding: 12px 12px;
  border-radius: 10px;
  text-decoration: none;

  :hover {
    background-color: ${(props) => props.theme.colorGrey6};
  }
  &.disabled,
  &:disabled {
    cursor: not-allowed;
    background-color: ${(props) => props.theme.bodybg013} !important;
    :hover {
      background-color: ${(props) => props.theme.colorGrey} !important;
    }
  }
  &.primary {
    background-color: ${(props) => props.theme.colorGreen};
    :hover {
      background-color: ${(props) => props.theme.colorGreen01};
    }
  }
  &.black {
    background-color: ${(props) => props.theme.bodybg03};
    :hover {
      background-color: ${(props) => props.theme.bodybg04};
    }
  }
  &.darkGray {
    // background-color: ${(props) => props.theme.bodybg013};
    border: 2px solid ${(props) => props.theme.bodybg013};
    box-shadow: ${(props) => props.theme.bodybg013} 0px 0px 10px 1px;

    :hover {
      background-color: #ababab;
    }
  }
  &.darkRed {
    background-color: red;
    :hover {
      background-color: red;
    }
  }
  &.orangBack {
    background-color: #8be05a;
    :hover {
      background-color: #a0ef72;
    }
  }
  &.outline {
    &-primary {
      background-color: transparent;
      border: 2px solid ${({ theme }) => theme.colorGreen};
      box-shadow: ${({ theme }) => theme.colorGreen} 0px 0px 10px 1px;

      &:hover {
        background-color: ${({ theme }) => theme.colorGreen};
        color: white;
      }
    }
    &-gray {
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.bodybg013};
      box-shadow: ${(props) => props.theme.bodybg013} 0px 0px 10px 1px;

      &:hover {
        background-color: ${(props) => props.theme.bodybg013};
      }
    }
    &-danger {
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.colorRed};
      box-shadow: ${(props) => props.theme.colorRed} 0px 0px 10px 1px;
      color: ${(props) => props.theme.colorRed};

      &:hover {
        background-color: ${(props) => props.theme.colorRed};
        color: white;
      }
    }
    &-disabled {
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.bodybg013};
      box-shadow: ${(props) => props.theme.bodybg013} 0px 0px 10px 1px;

      &:hover {
        background-color: ${(props) => props.theme.bodybg013};
      }
    }
    &-none {
      background-color: transparent;
      :hover {
        color: ${({ theme }) => theme.colorGreen};
        background-color: transparent;
      }
    }
  }
`
