import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { theme } from 'Theme/theme'

const current = theme(true)

function Providers({ children }) {
  return (
    <Router>
      <ThemeProvider theme={current}>{children}</ThemeProvider>
    </Router>
  )
}

export default Providers
