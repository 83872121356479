import React from 'react'
import { ImSpinner9 } from 'react-icons/im'

export function LoadingSpinner({ ...props }) {
  return <ImSpinner9 className='rotate-infinite-1' {...props} />
}

function AppLoadingSpinner({ ...props }) {
  return (
    <div className='p-10 text-center' {...props}>
      <LoadingSpinner />
    </div>
  )
}

export default AppLoadingSpinner
